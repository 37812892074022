*{
    margin:0;
   }
   
   #container{
       display: flex;
       flex-wrap: wrap;
       height: 95%;
       z-index: -2;
   }
   
   .contenedor-botones-lavadora{
     border: none;
     height: 100%;
     text-align: center;
     width:100%
   }
   
   .abrirButtonPrograma{
     height:35px
   }
   
   .cuerpoLavadora .contenedor-botones-lavadora button{
     font-size: 15;
     display: block;
     background-color:#0ba9f6;
     border: none;
     color: white;
     border-radius: 10px;
     text-align: center;
     text-decoration: none;
     cursor: pointer;
     margin: auto;      
   }
   
   
   .Lavadora{
     /* height: 250px; */
     overflow: hidden;
     margin-bottom: 15px;
   }
   
   
   .Lavadora > div{
     border: 0.5px $brand-color solid;
       text-align: center;
   }
   
   .numeroLavadora{
       position: relative;
       z-index: 2 ;
       background:  $brand-color;
       /*box-shadow: 0 10px 14px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
       height:30px;

       h3 {
        cursor: pointer;       
       }

       h3:hover{
        text-decoration: underline;
      }
     
       h3::after {
        display: inline-block;
        margin-left: 0.255em;
        vertical-align: middle;
        content: "";
        border-top: 0.3em solid;
        border-right: 0.3em solid transparent;
        border-bottom: 0;
        border-left: 0.3em solid transparent;
        transition: transform 0.5s;
      } 
    
      h3.open::after {
        transform: rotate(180deg);
      }
   }
   
   .numeroLavadora .btn-nomargin{
     float: none;
     margin-right: 0;
   }
   
   .numeroLavadora h3{
       display:inline-block;
       margin:auto;
       z-index: 2;
       color: white;
       font-size: 20px;  
   }
   
   .numeroLavadora button{
     position: relative;
     background-color: $brand-color;
     border: none;
     color: white;
     border-radius: 10px;
     text-align: center;
     text-decoration: none;
     cursor: pointer;
     margin-right:5px;
     float:right;
     margin-top: 5px;
     z-index: 2;
   }
   
   .cuerpoLavadora{
       position: relative;
       background: white;
       /* height: 71%; */
       z-index: 1;
       padding: 10px;
       box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.2), 0 0px 26px 0 rgba(0, 0, 0, 0.19);
   }
   
   .verticaltext{
     margin: auto;
     width: 1.75rem;
     word-wrap: break-word;
     white-space:pre-wrap; 
   }
   
   .ingresarQuimicos button{
     position: relative;
     display:flex;
     background-color: $brand-color;
     border: none;
     color: white;
     border-radius: 10px;
     text-align: center;
     text-decoration: none;
     cursor: pointer;
     margin: 5px;
     left: 30%;
   }
   
   .ingresarQuimicos p{
     display: inline-block;
   } 
   